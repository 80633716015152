<template>
  <div :class="$style.root">
    <slot />
  </div>
</template>

<style module>
.root:not(:empty) {
  padding-top: var(--font-default--line-height);
}
</style>
