<template>
  <div ref="element" :class="$style.root" tabindex="0">
    <header :class="$style.header">
      <slot name="header" />
    </header>

    <i18nLink :to="closePath" :class="$style.link" >
      <svgo-cross :class="$style.svg" />
    </i18nLink>

    <div :class="$style.content">
      <slot />
    </div>

    <LayoutModalContentFooterContainerComponent
      :class="$style.footer"
    >
      <slot name="footer" />
    </LayoutModalContentFooterContainerComponent>
  </div>
</template>

<script setup lang="ts">
const element = ref(null)

const props = defineProps({
  closePath: {
    type: Object,
    required: false
  }
})

onMounted(() => {
  element?.value?.focus()
})
</script>

<style module lang="scss">
.root {
  width: calc(var(--container--width) + (var(--unit--horizontal) * 2));
  max-width: calc(100% - var(--unit--horizontal) * 2);
  margin-left: auto;
  margin-right: auto;

  background-color: var(--color--background);
  color: var(--color--primary);

  position: relative;

  padding: var(--unit--vertical--large) calc(var(--unit--horizontal) * 2);

  grid-template-columns: 1fr var(--size--button);
  display: grid;
  grid-column-gap: var(--unit--horizontal);
  grid-template-areas: 'header link-to-close'
                       'content content'
                       'footer footer';

  @media (max-width: $breakpoint-s) {
    padding: var(--unit--vertical--large) var(--unit--horizontal);
  }
}

.root:focus {
  outline: none;
}


.header {
  margin-bottom: var(--font-default--line-height);
  grid-area: header;
}

.content {
  grid-area: content;
}

.link {
  composes: button from global;
  margin: 0;
  grid-area: link-to-close;

  position: sticky;
  top: var(--unit--vertical--large);
  z-index: 2;

  transform: translateY(calc((var(--unit--vertical--large) - var(--unit--vertical)) * -1)) translateX(calc(var(--size--button) - var(--size--button) + var(--unit--horizontal) * -1));
}

.svg {
  composes: button-svg from global;
}

.footer {
  grid-area: footer;
}
</style>
