<template>
  <div
    :class="$style.root"
    ref="element"
  >
    <LayoutModalContentComponent
      :class="$style.content"
      tabindex="0"
      ref="content"
      :close-path="automaticClosePathContent"
      @keyup.esc="closeSelfIfLightboxIsInactive()"
    >
      <template #header>
        <slot name="header" />
      </template>

      <slot />

      <template #footer>
        <slot name="footer" />
      </template>
    </LayoutModalContentComponent>
  </div>
</template>

<script setup lang="ts">
import { omit } from 'lodash-es'

const props = defineProps({
  closePath: {
    type: Object,
    required: false
  }
})

const mainStore = useMainStore()
const route = useRoute()
const element = ref(null)
const queryObject = omit(route.query || {}, ['modal', 'participantId'])

const automaticClosePathContent = computed(() => {
  // FIXME: extend
  const allowedRoutes = ['index', 'index-education']
  const prevRoute = mainStore?.prevRoute?.name?.split("_")[0]
  if (allowedRoutes?.includes(prevRoute)) {
    return { path: mainStore?.prevRoute?.path }
  }
  return props.closePath || { path: route.path, query: queryObject }
})

const automaticClosePath = computed(() => {
  return props.closePath || { path: route.path, query: queryObject }
})

const content = ref(null)

useHead({
  htmlAttrs: {
    'data-overflow-hidden': ''
  }
})

onUnmounted(() => {
  document.documentElement.removeAttribute('data-overflow-hidden')
})

const localePath = useLocalePath()
const router = useRouter()
const closeSelf = () => {
  router.push(localePath(automaticClosePath.value))
}

const closeSelfIfLightboxIsInactive = () => {
  const query = route?.query['lightbox-image-id'] || route?.query['lightbox-video-id']
  const hasLightBox = query?.length > 0
  if (!hasLightBox) {
    closeSelf()
  }
}

onClickOutside(content, (e) => {
  if (!e?.target?.classList?.value?.includes('menu-button')) {
    closeSelfIfLightboxIsInactive()
  }
}, {ignore: ['#cookie-consent']})

// NOTE this is temp fix: wait for resolve https://github.com/nuxt/nuxt/issues/23232
const query = computed(() => route.query)
watch(query, (to, from) => {
  setTimeout(() => {
    if (JSON.stringify(Object.keys(to)) !== JSON.stringify(Object.keys(from))) return
    if (content?.value?.$refs?.element instanceof HTMLElement) {
      content?.value?.$refs?.element?.scrollIntoView()
    }
  }, 500)
})

onMounted(() => {
  if (route?.hash) {
    const anchor = document.getElementById(route?.hash?.replace('#', ''))
    const modalCloseContainer = content?.value?.$refs?.element instanceof HTMLElement ? getComputedStyle(content?.value?.$refs?.element)?.getPropertyValue('padding-top') : 0
    const offset = parseInt(modalCloseContainer, 10) || 0
    if (!anchor) return
    const offsetTop = anchor?.offsetTop
    element?.value?.scroll({
      top: (offsetTop - offset),
      left: 0
    })
  }
})
</script>

<style module>
.root {
  position: fixed;
  z-index: var(--z-index--modal);

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: scroll;
}

.root:before {
  backdrop-filter: var(--backdrop-filter--light);
  content: '';
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
</style>
